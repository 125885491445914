<div class="header-container">
    <div class="logo-container">
        <a class="logo" href="#" (click)="navigateHome()">
            <img src="assets/images/general/logo-big.png">
        </a>
    </div>
</div>

<div class="header-container">
    <div *ngIf="account.isTrialTimeLeft && !account.plan">
        Kostenloser Test endet in: <strong>{{account.trialDaysLeft}}</strong> Tag(en) <!--<span *ngIf="account.paymentId"> | Credits aktueller Monat: <strong>{{account.monthlyCreditsUsed}} / {{account.monthlyCreditsQuota}}</strong></span> --> | <a href="/pages/booking"><button type="button" class="btn btn-success btn-sm">Jetzt buchen</button></a>
    </div>
  <div *ngIf="!account.isTrialTimeLeft && !account.plan">
        <strong>Ihr Testzeitraum ist leider abgelaufen!</strong> <!--<span *ngIf="account.paymentId"> | Credits aktueller Monat: <strong>{{account.monthlyCreditsUsed}} / {{account.monthlyCreditsQuota}}</strong></span> --> | <a href="/pages/booking"><button type="button" class="btn btn-success btn-sm">Jetzt buchen</button></a>
  </div>
  <div *ngIf="account.plan">
    Ihr aktuelles Paket: <strong>{{account.plan}}</strong>
  </div>



     <nb-actions  size="small">
            <nb-action class="user-action" *nbIsGranted="['view', 'user']">
                <nb-user  [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="'Mein Account'" picture="/assets/images/general/header_setting.png">
                </nb-user>
            </nb-action>
        </nb-actions>
</div>
