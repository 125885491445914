import {Injectable, Injector} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {catchError, map} from 'rxjs/operators';
import {Router} from '@angular/router';


/**
 * TokenInterceptor
 * @see https://angular.io/guide/http#intercepting-all-requests-or-responses
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {


  constructor(private injector: Injector, private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // do not intercept request whose urls are filtered by the injected filter


    const token = localStorage.getItem('jwt_token');

    if ( token ) {
      const JWT = `Bearer ${token}`;
      req = req.clone({
        setHeaders: {
          Authorization: JWT,
        },
      });


      return next.handle(req).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse && event.status === 401) {
            localStorage.removeItem('jwt_token');
          }
          return event;
        }), // @ts-ignore
        catchError((error: any, caught: Observable<HttpEvent<any>>)  =>  {
          if (error && error.status === 401) {
            localStorage.removeItem('jwt_token');
            this.router.navigate(['/auth/login']);
          }
      return;
      }));
    } else {
      // Request is sent to server without authentication so that the client code
      // receives the 401/403 error and can act as desired ('session expired', redirect to login, aso)
      return next.handle(req);
    }


  }

}



