import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../config/config.service';


@Injectable({
  providedIn: 'root'
})
export class AccountService {


  baseUrl =  `${ConfigService.apiBaseUrl}/account`;

  constructor(private http: HttpClient) {

  }

  async get() {

    const ret = await this.http.get<any>(`${this.baseUrl}`, {
      reportProgress: false,
      responseType: 'json'

    }).toPromise();
    if (ret.success === true) {
      return ret.data;
    } else {
      return Promise.reject();
    }
  }


  async put (newData: Account) {

    const ret = await this.http.put<any>(`${this.baseUrl}`, newData, {
      reportProgress: false,
      responseType: 'json'

    }).toPromise();
    if (ret.success === true) {
      return ret.data;
    } else {
      return Promise.reject();
    }
  }


  async generateApiToken () {

    const ret = await this.http.put<any>(`${this.baseUrl}/publicapi`, null , {
      reportProgress: false,
      responseType: 'json'

    }).toPromise();
    if (ret.success === true) {
      return ret.data;
    } else {
      return Promise.reject();
    }
  }

  async getApiToken () {

    const ret = await this.http.get<any>(`${this.baseUrl}/publicapi`,  {
      reportProgress: false,
      responseType: 'json'

    }).toPromise();
    if (ret.success === true) {
      return ret.data;
    } else {
      return Promise.reject();
    }
  }



}
