import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NbAuthJWTToken, NbAuthModule, NbPasswordAuthStrategy} from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';
import {ConfigService} from '../config/config.service';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  AnalyticsService,
  LayoutService,
  SeoService,
  StateService,
} from './utils';

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  ...NbAuthModule.forRoot({

    strategies: [
      NbPasswordAuthStrategy.setup({

        name: 'email',
        token: {
          class: NbAuthJWTToken,
          key: 'data.token'

        },
        baseEndpoint: ConfigService.apiBaseUrl,
        login: {
          redirect: {
            success: '/pages/vat/check/manual', // welcome page path
            failure: null, // stay on the same page
          },
          // ...
          endpoint: '/auth/login',
          method: 'post',

        },
        register: {
          redirect: {
            success: '/auth/login', // welcome page path
            failure: null, // stay on the same page
          },
          // ...
          endpoint: '/auth/signup',
          method: 'post',
        },
        requestPass: {
          // ...
          endpoint: '/auth/password/request',
          method: 'post',
        },
        resetPass: {
          // ...
          endpoint: '/auth/password/reset',
          method: 'put',
          resetPasswordTokenKey: 'reset_token'
        },
        errors: {
          // Override the getter of errors functions
          // res: is the HttpResponse that you get from your backend
          getter: (module, res, options) => {
            return res.error?.errors ? res.error?.errors :
            'Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.';
          },
        }
      }),
    ],
    forms: {
      login: {
        redirectDelay: 100,
        strategy: 'email',
        rememberMe: true,
        showMessages: {     // show/not show success/error messages
          success: false,
          error: true,
        },
      },
      register: {
        strategy: 'email',
        rememberMe: true,
        showMessages: {     // show/not show success/error messages
          success: true,
          error: true,
        },
      },
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,

  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  AnalyticsService,
  LayoutService,
  SeoService,
  StateService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
