
export let ConfigService: any = {};
const env = 'prod'; // here you can switch used environment "local", "prod"
ConfigService.env = env;



// @ts-ignore
if (env === 'local') {
  ConfigService.apiBaseUrl = 'http://localhost:3000/api';
  ConfigService.webAppHost = 'http://localhost:4200';
} else if (env === 'prod') {
  // ConfigService.apiBaseUrl = 'https://ustid-webapp.azurewebsites.net/api';
   ConfigService.apiBaseUrl = 'https://app.ust-id-pruefen.de/api';
   ConfigService.webAppHost = 'https://app.ust-id-pruefen.de';
} else {
  alert('INVALID CONFIG!');
}

