import { Injectable } from '@angular/core';
import {ConfigService} from '../config/config.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  baseUrl = `${ConfigService.apiBaseUrl}/payment`;

  constructor(private http: HttpClient) { }

  async getStripeCheckoutUrl (priceId: string, accountId: string) {
    const ret = await this.http.get<any>(`${this.baseUrl}/getsession/${priceId}/${accountId}`, {
      reportProgress: false,
      responseType: 'json'
    }).toPromise();
    if (ret.success === true) {
      return ret.data;
    } else {
      return Promise.reject();
    }
  }


  async openCustomerPortal () {
      const token = localStorage.getItem('jwt_token');
      window.open(`${this.baseUrl}/create-customer-portal-session?token=${token}`, '_self');
  }

}
