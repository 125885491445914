import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';


import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {NbAuthJWTToken, NbAuthService,  NbTokenService} from '@nebular/auth';
import {NavigationEnd, Router} from '@angular/router';
import {AccountService} from '../../../services/account.service';
import {PaymentService} from '../../../services/payment.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  // @TODO: We can remove the whole themes logic :)
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [ { title: 'Meine Daten' },  { title: 'Kundenbereich' },  { title: 'Abmelden' } ];
  account: any = {
    monthlyCreditsUsed: 10,
    monthlyCreditsQuota: 100,
  };
  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private layoutService: LayoutService,
              private authService: NbAuthService,
              private router: Router,
              private tokenService: NbTokenService,
              private accountSvc: AccountService,
              private paymentSvc: PaymentService

  ) {

    router.events.subscribe(async (val) => {
      // see also
      if (val instanceof NavigationEnd ) {
        try {
          this.account = await accountSvc.get();


          try {
            // get user email for freshdesk support widget
            this.account.users.email;
            // @ts-ignore
            FreshworksWidget('identify', 'ticketForm', {
              email: this.account.users.email,
            });
          } catch (e) {
            console.error(`Could not get users email for freshdesk  ${e}`);
          }

          // calculate if trial time left
        } catch (e) {
          console.error(`Could not load account data ${e}`);
        }
      }

    });
  }



  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;




    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {

        if (token.isValid()) {
          // here we receive a payload from the token and assigns it to our `user` variable
          this.user = token.getPayload();
        }
      });



    this.menuService.onItemClick()
      .subscribe((event) => {
        if (event.item.title === 'Abmelden') {
          this.tokenService.clear();
          localStorage.removeItem('jwt_token');
          this.router.navigate(['auth/login']);

        } else if (event.item.title === 'Meine Daten') {
          this.router.navigate(['pages/settings/account']);
        } else if (event.item.title === 'Kundenbereich') {
          // if user is customer - navigate to stripe customer portal. If not navigate to booking page
          if (this.account && this.account.plan) {

              this.paymentSvc.openCustomerPortal();
          } else {
            this.router.navigate(['pages/booking']);
          }
        }
      });

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
