import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import { NbAuthService } from '@nebular/auth';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor( private router: Router) { }

  async canActivate() {
    try {

      const token = localStorage.getItem('jwt_token');

      if (!token) {
        this.router.navigate(['/auth/login']);
      }
      return !!token;
    } catch (err) {
      console.info(`User was logged out because ${err}`);
      this.router.navigate(['auth/login']);
    }

  }
}

